import React from 'react';
import HeroSection from '../components/HomePage/HeroSection';
import Header from '../components/Header.jsx';
import Footer from '../components/Footer';
import PricingSection from '../components/HomePage/PricingSection.jsx';
import SecondaryFeatures from '../components/HomePage/SecondaryFeatures.jsx';
import ProductInformationSection from '../components/HomePage/ProductInformationSection.jsx';
import CallToAction from '../components/HomePage/CallToAction.jsx';

function Home() {
  return (
    <div className="relative min-h-screen overflow-hidden bg-gray-100 dark:bg-gray-900">
      
      {/* Background Gradients */}
      {/* <div
        className="absolute inset-x-0 top-0 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-blue-500 to-pink-400 opacity-30 dark:opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div> */}

      {/* Additional Gradients */}
      <div
        className="absolute inset-x-0 top-[60%] transform-gpu overflow-hidden blur-3xl"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-blue-500 to-pink-400 opacity-30 dark:opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      {/* Bottom Right Gradient */}
      <div
        className="absolute inset-x-0 top-[60%] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] z-0"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-blue-400 to-pink-500 opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      {/* Bottom Left Gradient */}
      <div
        className="absolute inset-x-0 top-[60%] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] z-0"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/4 bg-gradient-to-tr from-blue-400 to-pink-500 opacity-30 sm:left-[calc(50%-66rem)] sm:w-[62.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      {/* Middle Left Gradient */}
      <div
        className="absolute inset-x-0 top-0 transform-gpu overflow-hidden blur-3xl sm:top-60 z-0"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(25%-6rem)] aspect-[4200/1000] w-[76.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-blue-400 to-pink-500 opacity-30 md:opacity-80 sm:left-[calc(25%-20rem)] sm:w-[76rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      {/* Middle Right Gradient */}
      <div
        className="absolute inset-x-0 top-52 transform-gpu overflow-hidden blur-3xl  z-0"
        aria-hidden="true"
      >
        <div
          className="relative aspect-[4200/1000] w-[36.125rem] translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-blue-400 to-pink-500 opacity-80 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      {/* Middle 1/2 Right Gradient */}
      <div
        className="absolute inset-x-0 top-1/3 transform-gpu overflow-hidden blur-3xl  z-0"
        aria-hidden="true"
      >
        <div
          className="relative aspect-[3200/1000] w-[70.125rem] translate-x-1/2 rotate-[120deg] bg-gradient-to-tr from-blue-400 to-pink-500 opacity-80 sm:left-[calc(60%-30rem)] sm:w-[62.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      <div
        className="absolute inset-x-0 top-1/3 transform-gpu overflow-hidden blur-3xl "
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-blue-500 to-pink-400 opacity-30 dark:opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      {/* Content Sections */}
      <Header />
      <HeroSection />
      <ProductInformationSection/>
      <SecondaryFeatures/>
      <PricingSection />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default Home;
