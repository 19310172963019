import React from 'react';
import {
  ExternalLinkIcon,
  PencilIcon,
  TrashIcon,
  CameraIcon,
} from '@heroicons/react/outline';
import { Tooltip } from 'react-tooltip';

const ProductsTable = ({ items, onEdit, onDelete, onEditPhotos }) => {
  const defaultImageUrl = 'https://cdn-icons-png.flaticon.com/512/3342/3342243.png';

  const StatusIndicator = ({ status, tooltip }) => (
    <div className="relative flex items-center z-0">
      <div
        className={`h-4 w-4 rounded-full ${status === 'active' || status === 'completed' ? 'bg-green-500' : 'bg-gray-500'}`}
        data-tip={tooltip}
      ></div>
      <Tooltip place="top" type="dark" effect="solid" />
    </div>
  );

  return (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg mt-1 h-[60vh]">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="sticky top-0 z-10 text-xs text-gray-700 dark:text-gray-300 uppercase bg-gray-50 dark:bg-gray-700">
          <tr>
            <th scope="col" className="py-3 px-6">Image</th>
            <th scope="col" className="py-3 px-6">SKU</th>
            <th scope="col" className="py-3 px-6">Product Name</th>
            <th scope="col" className="py-3 px-6">Category</th>
            <th scope="col" className="py-3 px-6">Price</th>
            <th scope="col" className="py-3 px-6">Quantity</th>
            <th scope="col" className="py-3 px-6">Status</th>
            <th scope="col" className="py-3 px-6">Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.length > 0 ? (
            items.map((item, index) => {
              const isDraftOrNotPhotographed = item.listingStatus === 'draft' || item.photographyStatus !== 'completed' || item.ebayListingStatus !== 'active';
              const rowClass = isDraftOrNotPhotographed ? 'bg-gray-200 dark:bg-gray-700' : 'bg-white dark:bg-gray-800';

              return (
                <tr
                  key={item._id}
                  className={`${rowClass} border-b dark:border-gray-700 ${index === items.length - 1 ? 'table-last-item' : ''}`}
                >
                  <td className="py-4 px-6">
                    <img
                      src={item.images && item.images.length > 0 ? item.images[0].awsUrl : defaultImageUrl}
                      alt={item.name}
                      className="h-20 w-20 rounded-full object-scale-down cursor-pointer"
                      onClick={() => item.photographyStatus === 'completed' && onEditPhotos(item)}
                    />
                  </td>
                  <td className="py-4 px-6">{item.sku}</td>
                  <td className="py-4 px-6">{`${item.brand} ${item.model} ${item.item_description}`}</td>
                  <td className="py-4 px-6">{item.category}</td>
                  <td className="py-4 px-6">${item.price.toFixed(2)}</td>
                  <td className="py-4 px-6">{item.quantity}</td>
                  <td className="py-4 px-6">
                    <div className="flex flex-col items-start space-y-2">
                      <div className="flex items-center space-x-2">
                        <StatusIndicator status={item.ebayListingStatus} tooltip={`eBay Listing Status: ${item.ebayListingStatus}`} />
                        <span className="text-xs text-gray-600 dark:text-gray-300">eBay Status</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <StatusIndicator status={item.photographyStatus} tooltip={`Photography Status: ${item.photographyStatus}`} />
                        <span className="text-xs text-gray-600 dark:text-gray-300">Photography Status</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <StatusIndicator status={item.listingStatus} tooltip={`Listing Status: ${item.listingStatus}`} />
                        <span className="text-xs text-gray-600 dark:text-gray-300">Record Status</span>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6 flex justify-start gap-4">
                    <button onClick={() => onEdit(item)} className="text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-200 dark:focus:ring-indigo-800 font-medium rounded-lg text-sm px-3 py-2">
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    {item.ebayDetails?.ebayListingId && (
                      <a
                        href={`https://www.ebay.com/itm/${item.ebayDetails.ebayListingId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-200 dark:focus:ring-indigo-800 font-medium rounded-lg text-sm px-3 py-2"
                      >
                        <ExternalLinkIcon className="h-5 w-5" />
                      </a>
                    )}
                    <button onClick={() => onDelete(item)} className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-200 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-3 py-2">
                      <TrashIcon className="h-5 w-5" />
                    </button>
                    {item.photographyStatus === 'completed' && (
                      <button onClick={() => onEditPhotos(item)} className="text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-200 dark:focus:ring-indigo-800 font-medium rounded-lg text-sm px-3 py-2">
                        <CameraIcon className="h-5 w-5" />
                      </button>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8" className="text-center py-4">
                No products available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsTable;
