import React from 'react';

const ManageConnectionsModal = ({ isOpen, closeModal, connections, selectedConnections, setSelectedConnections, setFormData }) => {

  const handleConnectionChange = (id) => {
    const updatedConnections = selectedConnections.includes(id)
      ? selectedConnections.filter(connectionId => connectionId !== id)
      : [...selectedConnections, id];

    setSelectedConnections(updatedConnections);

    // Update the form data with the new selections
    setFormData((prevData) => ({
      ...prevData,
      listToChannels: updatedConnections.map(connectionId => {
        const conn = connections.find(c => c.id === connectionId);
        return {
          platform: conn.platform,
          accountId: conn.id,
          listingStatus: 'draft',
          marketplaceId: conn.marketplaceId || 'EBAY_US',
        };
      }),
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="relative w-full max-w-4xl p-6 mx-auto bg-white rounded-lg shadow-xl">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">Manage Sales Channels</h2>
          <button onClick={closeModal} className="text-gray-400 hover:text-gray-600">
            <span className="sr-only">Close</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <p className="mb-4 text-gray-600">
          Manage which platforms you would like to list this product on. Select or deselect available connections to determine the sales channels.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {connections.map((conn) => (
            <div
              key={conn.id}
              className="flex justify-between items-center p-4 bg-gray-100 rounded-lg border border-gray-300"
            >
              <div>
                <h3 className="text-lg font-semibold text-gray-800">{conn.accountName}</h3>
                <p className="text-sm text-gray-600">{conn.platform}</p>
              </div>

              <div>
                <input
                  type="checkbox"
                  checked={selectedConnections.includes(conn.id)}
                  onChange={() => handleConnectionChange(conn.id)}
                  className="h-6 w-6 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end mt-6">
          <button
            onClick={closeModal}
            className="inline-flex items-center px-4 py-2 bg-indigo-600 text-white text-sm font-medium rounded-md shadow-sm hover:bg-indigo-700"
          >
            Save and Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageConnectionsModal;
