import React, { useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import ManageConnectionsModal from './ManageConnectionsModal';
import { useSelector } from 'react-redux';

const ProductConnections = ({ selectedConnections, setSelectedConnections, inventoryRecord }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { connections: allConnections } = useSelector((state) => state.connection);

  // Handle merging of selected and available connections for existing and new products
  useEffect(() => {
    if (inventoryRecord?.listToChannels?.length > 0) {
      // Preselect connections from inventoryRecord for existing product using 'id' instead of 'accountId'
      const listToChannelIds = inventoryRecord.listToChannels.map(channel => channel.id);
      setSelectedConnections(listToChannelIds);
    } else {
      // Preselect default connections for new products
      const defaultConnections = allConnections
        .filter(conn => conn.listToByDefault)
        .map(conn => conn.id);
      setSelectedConnections(defaultConnections);
    }
  }, [inventoryRecord, allConnections, setSelectedConnections]);

  // Function to determine the status indicator (active, disabled, default state)
  const getStatusIndicator = (state) => {
    switch (state) {
      case 'active':
        return <span className="h-4 w-4 bg-green-500 rounded-full" />;
      case 'disabled':
        return <span className="h-4 w-4 bg-red-500 rounded-full" />;
      default:
        return <span className="h-4 w-4 bg-yellow-500 rounded-full" />;
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md w-full">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100">Sales Channels</h3>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex justify-center text-gray-500 hover:text-gray-700">
              <DotsHorizontalIcon className="w-5 h-5" />
            </Menu.Button>
          </div>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-40 bg-white shadow-lg ring-1 ring-black ring-opacity-5 rounded-md focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => setModalOpen(true)}
                      className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                    >
                      Manage Sales Channels
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      <ul className="space-y-2">
        {allConnections.map((conn) => (
          <li key={conn.id} className="flex justify-between items-center py-2">
            <div className="flex items-center space-x-2">
              {getStatusIndicator(conn.state)}
              <span className="text-sm dark:text-white">
                {conn.accountName} ({conn.platform})
              </span>
            </div>
            <span
              className={`text-xs px-2 py-1 rounded-full ${selectedConnections.includes(conn.id)
                ? 'bg-green-100 text-green-800'
                : 'bg-red-100 text-red-800'
                }`}
            >
              {selectedConnections.includes(conn.id) ? 'Enabled' : 'Disabled'}
            </span>
          </li>
        ))}
      </ul>

      {isModalOpen && (
        <ManageConnectionsModal
          isOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
          connections={allConnections}
          selectedConnections={selectedConnections}
          setSelectedConnections={setSelectedConnections}
        />
      )}
    </div>
  );
};

export default ProductConnections;
