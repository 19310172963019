import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  fetchInventoryItems,
  fetchInventoryItemById,
  createInventoryItem,
  updateInventoryItem,
  deleteInventoryItem,
  fetchPendingPhotographyItems,
  uploadPhotographyImages,
} from './inventoryService';

const initialState = {
  items: [],           // All inventory items fetched from the server
  displayedItems: [],   // The subset of items currently displayed (for lazy loading)
  pendingPhotographyItems: [],
  itemDetails: {},
  loading: false,
  loadingMore: false,   // Flag for loading more items
  error: null,
  page: 1,              // Current page for lazy loading
  itemsPerPage: 10,     // Number of items to load per page
  hasMoreItems: true,   // Flag indicating if there are more items to load
};

// Fetch initial inventory items
export const getInventoryItems = createAsyncThunk(
  'inventory/getInventoryItems',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchInventoryItems(token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch more inventory items from local state
export const getMoreInventoryItems = createAsyncThunk(
  'inventory/getMoreInventoryItems',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState().inventory;
      const startIndex = state.page * state.itemsPerPage;
      const endIndex = startIndex + state.itemsPerPage;
      const moreItems = state.items.slice(startIndex, endIndex);

      return moreItems;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Fetch individual inventory item by ID
export const getInventoryItem = createAsyncThunk(
  'inventory/getInventoryItem',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchInventoryItemById(token, id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Add a new inventory item
export const addInventoryItem = createAsyncThunk(
  'inventory/addInventoryItem',
  async (inventoryData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await createInventoryItem(token, inventoryData);
      return await fetchInventoryItems(token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Update an existing inventory item
export const updateItem = createAsyncThunk(
  'inventory/updateItem',
  async ({ id, inventoryData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await updateInventoryItem(token, id, inventoryData);
      return await fetchInventoryItems(token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Delete an inventory item
export const removeInventoryItem = createAsyncThunk(
  'inventory/removeInventoryItem',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await deleteInventoryItem(token, id);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch pending photography items
export const getPendingPhotographyItems = createAsyncThunk(
  'inventory/getPendingPhotographyItems',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchPendingPhotographyItems(token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Upload images for a specific inventory item
export const uploadImagesForItem = createAsyncThunk(
  'inventory/uploadImagesForItem',
  async ({ inventoryItemId, formData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await uploadPhotographyImages(token, inventoryItemId, formData);
      return await fetchPendingPhotographyItems(token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Upload edited images and refresh both inventory and pending photography lists
export const uploadEditedImagesForItem = createAsyncThunk(
  'inventory/uploadEditedImagesForItem',
  async ({ inventoryItemId, formData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await uploadPhotographyImages(token, inventoryItemId, formData);
      const [refreshedItems, pendingPhotographyItems] = await Promise.all([
        fetchInventoryItems(token),
        fetchPendingPhotographyItems(token),
      ]);
      return { refreshedItems, pendingPhotographyItems };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get inventory items and populate displayed items for the first page
      .addCase(getInventoryItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventoryItems.fulfilled, (state, action) => {
        state.items = action.payload;
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.loading = false;
        state.page = 1;
        state.hasMoreItems = state.items.length > state.itemsPerPage;
      })
      .addCase(getInventoryItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Fetch more items from the local state
      .addCase(getMoreInventoryItems.pending, (state) => {
        state.loadingMore = true;
      })
      .addCase(getMoreInventoryItems.fulfilled, (state, action) => {
        state.displayedItems = [...state.displayedItems, ...action.payload];
        state.page += 1;
        state.loadingMore = false;
        state.hasMoreItems = action.payload.length === state.itemsPerPage;
      })
      .addCase(getMoreInventoryItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingMore = false;
      })
      // Fetch individual inventory item by ID
      .addCase(getInventoryItem.fulfilled, (state, action) => {
        state.itemDetails = action.payload;
      })
      // Add new inventory item and refresh list
      .addCase(addInventoryItem.fulfilled, (state, action) => {
        state.items = action.payload;
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.loading = false;
      })
      // Update inventory item and refresh list
      .addCase(updateItem.fulfilled, (state, action) => {
        state.items = action.payload;
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.loading = false;
      })
      // Delete inventory item and refresh displayed items
      .addCase(removeInventoryItem.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item._id !== action.payload);
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.loading = false;
      })
      // Fetch pending photography items
      .addCase(getPendingPhotographyItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPendingPhotographyItems.fulfilled, (state, action) => {
        state.pendingPhotographyItems = action.payload;
        state.loading = false;
      })
      .addCase(getPendingPhotographyItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Upload images for an item and update pending photography list
      .addCase(uploadImagesForItem.fulfilled, (state, action) => {
        state.pendingPhotographyItems = action.payload;
        state.loading = false;
      })
      .addCase(uploadImagesForItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Upload edited images, refresh both inventory and pending photography lists
      .addCase(uploadEditedImagesForItem.fulfilled, (state, action) => {
        state.items = action.payload.refreshedItems;
        state.pendingPhotographyItems = action.payload.pendingPhotographyItems;
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.loading = false;
      })
      .addCase(uploadEditedImagesForItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadEditedImagesForItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default inventorySlice.reducer;
