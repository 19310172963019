import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_URL

const baseUrl = `${apiUrl}/api/inventory`
const photographyUrl = `${apiUrl}/api/photography`

// Fetch all inventory items
export const fetchInventoryItems = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const { data } = await axios.get(baseUrl, config)
  return data
}

// Fetch a single inventory item by ID
export const fetchInventoryItemById = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const { data } = await axios.get(`${baseUrl}/${id}`, config)
  return data
}

// Create a new inventory item
export const createInventoryItem = async (token, inventoryData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const { data } = await axios.post(baseUrl, inventoryData, config)
  return data
}

// Update an existing inventory item
export const updateInventoryItem = async (token, id, inventoryData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  console.log(`Updated Data: ${inventoryData}`)

  const { data } = await axios.put(`${baseUrl}/${id}`, inventoryData, config)
  return data
}

// Delete an inventory item
export const deleteInventoryItem = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  await axios.delete(`${baseUrl}/${id}`, config)
}

// Fetch inventory items with photographyStatus 'pending'
export const fetchPendingPhotographyItems = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const { data } = await axios.get(`${baseUrl}/photography/pending`, config)
  return data
}

// Function to upload images for a specific inventory item
export const uploadPhotographyImages = async (
  token,
  inventoryItemId,
  formData
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  }

  const { data } = await axios.post(
    `${photographyUrl}/upload-images/${inventoryItemId}`,
    formData,
    config
  )
  return data
}
