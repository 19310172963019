import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { getInventoryItem, updateItem, addInventoryItem } from '../../features/inventory/inventorySlice';
import { fetchConnections } from '../../features/connections/connectionSlice';
import Spinner from '../../components/Spinner';
import ProductBasicDetails from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductBasicDetails';
import ProductOrganization from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductOrganization';
import ProductConnections from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductConnections';
import ProductMediaSection from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductMediaSection';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductDetailsPage = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { itemDetails, loading } = useSelector((state) => state.inventory);
  const { connections } = useSelector((state) => state.connection);

  // Dark mode state
  const [darkMode, setDarkMode] = useState(() => localStorage.getItem('theme') === 'dark');
  useEffect(() => {
    const handleThemeChange = () => {
      setDarkMode(localStorage.getItem('theme') === 'dark');
    };
    window.addEventListener('themeChange', handleThemeChange);
    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  // Form data state
  const [formData, setFormData] = useState({
    basicDetails: {
      brand: '',
      model: '',
      condition: 'Select Condition',
      quantity: 1,
      item_description: '',
      dimensions: { length: '', width: '', height: '', unit: 'INCH' },
      weight: { value: '', unit: 'POUND' },
      shippingMethods: ['UPSGround'],
      price: 0,
      additional_info: '',
      bestOffer: false,
      autoAcceptPrice: 0,
      autoDeclinePrice: 0,
      acceptsReturns: true, // Add the "Returns Accepted" field
    },
    category: '', // Add category field here
    listToChannels: [],
  });

  const [formErrors, setFormErrors] = useState({});
  const [selectedConnections, setSelectedConnections] = useState([]);

  const isEditMode = Boolean(productId);
  const pageTitle = isEditMode ? 'Edit Product' : 'Create New Product';

  // Fetch item details and connections
  useEffect(() => {
    if (productId) {
      dispatch(getInventoryItem(productId));
    }
    dispatch(fetchConnections());
  }, [dispatch, productId]);

  // Populate form data with existing item details
  useEffect(() => {
    if (itemDetails) {
      setFormData((prevData) => ({
        ...prevData,
        basicDetails: {
          brand: itemDetails.brand || '',
          model: itemDetails.model || '',
          item_description: itemDetails.item_description || '',
          condition: itemDetails.condition || 'Select Condition',
          quantity: itemDetails.quantity || 1,
          dimensions: itemDetails.dimensions || { length: '', width: '', height: '', unit: 'INCH' },
          weight: itemDetails.weight || { value: '', unit: 'POUND' },
          shippingMethods: itemDetails.shippingMethods || ['UPSGround'],
          additional_info: itemDetails.additional_info || '',
          price: itemDetails.price || 0,
          bestOffer: itemDetails?.ebayDetails?.bestOfferTerms?.bestOfferEnabled || false,
          autoAcceptPrice: itemDetails?.ebayDetails?.bestOfferTerms?.autoAcceptPrice?.value || 0,
          autoDeclinePrice: itemDetails?.ebayDetails?.bestOfferTerms?.autoDeclinePrice?.value || 0,
          acceptsReturns: itemDetails?.acceptsReturns || true, // Set default to true
        },
        category: itemDetails.category || '', // Load category value
        listToChannels: itemDetails.listToChannels || [],
      }));

      // Set selected connections based on itemDetails.listToChannels if available
      setSelectedConnections(
        Array.isArray(itemDetails.listToChannels)
          ? itemDetails.listToChannels.map((channel) => channel.accountId)
          : []
      );
    }
  }, [itemDetails]);

  // Handle default connections for new products
  useEffect(() => {
    if (!isEditMode && connections.length > 0 && formData.listToChannels.length === 0) {
      const defaultConnections = connections
        .filter((conn) => conn.listToByDefault)
        .map((conn) => ({
          platform: conn.platform,
          accountId: conn.id, // Using MongoDB `id` instead of accountId
          listingStatus: 'draft',
        }));

      setSelectedConnections(defaultConnections.map((conn) => conn.accountId));
      setFormData((prevData) => ({
        ...prevData,
        listToChannels: defaultConnections,
      }));
    }
  }, [connections, formData.listToChannels.length, isEditMode]);

  // Update form data for basic details
  const handleBasicDetailsChange = (updatedBasicDetails) => {
    setFormData((prevData) => ({
      ...prevData,
      basicDetails: updatedBasicDetails,
    }));
  };

  // Validation logic
  const handleFormValidation = () => {
    const errors = {};
    const concatenatedString = `${formData.basicDetails.brand} ${formData.basicDetails.model} ${formData.basicDetails.item_description}`.trim();

    if (concatenatedString.length > 80) {
      errors.basicDetails = 'Brand, model, and description cannot exceed 80 characters.';
    }

    const requiredFields = ['brand', 'model', 'condition', 'quantity', 'dimensions.length', 'dimensions.width', 'dimensions.height', 'weight.value'];
    requiredFields.forEach((field) => {
      const fieldValue = field.includes('.')
        ? formData.basicDetails[field.split('.')[0]][field.split('.')[1]]
        : formData.basicDetails[field];
      if (!fieldValue || fieldValue === '') {
        errors[field] = `${field.replace('.', ' ')} is required.`;
      }
    });

    // Category validation
    if (!formData.category || formData.category.trim() === '') {
      errors.category = 'Category is required.';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Function to check if all required fields are filled
  const checkAllRequiredFieldsFilled = () => {
    const requiredFields = ['brand', 'model', 'condition', 'quantity', 'dimensions.length', 'dimensions.width', 'dimensions.height', 'weight.value', 'category'];
    return requiredFields.every((field) => {
      const fieldValue = field.includes('.')
        ? formData.basicDetails[field.split('.')[0]][field.split('.')[1]]
        : formData.basicDetails[field];
      return fieldValue && fieldValue.toString().trim() !== '';
    });
  };

  // Function to determine listing status
  const determineListingStatus = () => {
    return checkAllRequiredFieldsFilled() ? 'completed' : 'draft';
  };

  // Function to convert formData to the expected payload format
  const convertFormDataToPayload = () => {
    const {
      brand,
      model,
      condition,
      quantity,
      item_description,
      dimensions,
      weight,
      shippingMethods,
      price,
      additional_info,
      bestOffer,
      autoAcceptPrice,
      autoDeclinePrice,
      acceptsReturns, // Include the new "Returns Accepted" field
    } = formData.basicDetails;

    return {
      item_description,
      additional_info,
      condition,
      brand,
      model,
      category: formData.category,
      price,
      quantity,
      dimensions: {
        length: dimensions.length,
        width: dimensions.width,
        height: dimensions.height,
        unit: dimensions.unit || 'INCH',
      },
      weight: {
        value: weight.value,
        unit: weight.unit || 'POUND',
      },
      acceptsReturns, // Include the "Returns Accepted" field
      shippingMethods,
      bestOffer,
      autoAcceptPrice,
      autoDeclinePrice,
      listToChannels: formData.listToChannels,
      recordCompletionStatus: determineListingStatus(), // Update status
    };
  };

  // Handle save button
  const handleSave = () => {
    if (handleFormValidation()) {
      const submissionData = convertFormDataToPayload();

      if (isEditMode) {
        dispatch(updateItem({ id: productId, inventoryData: submissionData }))
          .then(() => {
            toast.success('Product updated successfully!');
            navigate('/arbie/products');
          })
          .catch((error) => toast.error('Failed to update product.'));
      } else {
        dispatch(addInventoryItem(submissionData))
          .then(() => {
            toast.success('Product created successfully!');
            navigate('/arbie/products');
          })
          .catch((error) => toast.error('Failed to create product.'));
      }
    } else {
      Object.values(formErrors).forEach((error) => toast.warn(error));
    }
  };

  if (loading) return <Spinner />;

  return (
    <div className={`container mx-auto p-6 lg:pt-4 lg:px-4 ${darkMode ? 'dark' : ''}`}>
      <ToastContainer />
      <div className="flex justify-between mb-4 items-center">
        <div className="flex items-center">
          <button onClick={() => navigate(-1)} className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100">
            <ArrowLeftIcon className="h-6 w-6 mr-2" />
          </button>
          <h1 className="text-2xl font-bold dark:text-gray-100">{pageTitle}</h1>
        </div>
        <div className="flex space-x-2">
          <button onClick={handleSave} className="bg-green-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-green-600 text-sm">
            Save
          </button>
          <button className="bg-red-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-red-600 text-sm">Delete</button>
          <button className="bg-indigo-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-indigo-600 text-sm">Archive</button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
        <div className="lg:col-span-3">
          <ProductBasicDetails
            product={formData.basicDetails}
            setBasicDetails={handleBasicDetailsChange}
            formErrors={formErrors}
            darkMode={darkMode}
          />
        </div>

        <div className="lg:col-span-1">
          <ProductConnections
            connections={connections}
            selectedConnections={selectedConnections}
            setSelectedConnections={setSelectedConnections}
            inventoryRecord={itemDetails}
            darkMode={darkMode}
          />
          <div className="mt-8">
            <ProductOrganization category={formData.category} setFormData={setFormData} darkMode={darkMode} />
          </div>
        </div>
      </div>

      <div className="mt-0">
        <ProductMediaSection media={itemDetails?.images || []} darkMode={darkMode} />
      </div>
    </div>
  );
};

export default ProductDetailsPage;
