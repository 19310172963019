import React, { useState, useEffect } from 'react';

const ProductOrganization = ({ category = '', setFormData, darkMode }) => {
  const [categoryValue, setCategoryValue] = useState('');

  // Populate the input field if an existing category is passed
  useEffect(() => {
    if (category) {
      setCategoryValue(category);
    }
  }, [category]);

  // Handle input changes and ensure no spaces in the value
  const handleCategoryChange = (e) => {
    const valueWithoutSpaces = e.target.value.replace(/\s+/g, ''); // Remove spaces
    setCategoryValue(valueWithoutSpaces);
    setFormData((prevData) => ({
      ...prevData,
      category: valueWithoutSpaces,
    }));
  };

  return (
    <div className={`p-6 rounded-lg shadow-md ${darkMode ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-900'}`}>
      <h3 className="text-lg font-bold mb-4">Product Organization</h3>

      {/* Category Input */}
      <div className="mb-6">
        <label className="block text-sm font-medium mb-2">Category</label>
        <input
          type="text"
          value={categoryValue}
          onChange={handleCategoryChange}
          className="w-full p-2 border rounded bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
          placeholder="Enter category (no spaces)"
          required
        />
      </div>
    </div>
  );
};

export default ProductOrganization;
