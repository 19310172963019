import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import {
  BellIcon,
  SunIcon,
  MoonIcon,
  ShoppingCartIcon,
  ChartSquareBarIcon,
  ViewGridIcon,
  CubeIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';

import { ShareIcon } from '@heroicons/react/outline';

import { reset, logout } from '../../../features/auth/authSlice';
import arbiEngineLogoDark from '../../../images/arbienginelogo.png';
import arbiEngineLogoLight from '../../../images/arbieBlackNew.png';
import UserWalkthroughModal from '../UserWalkthroughModal';
import ReferFriendModal from '../../ReferFriendModal'; // Import the ReferFriendModal

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function generateWords() {
  const dataWords = [
    'pieces of information',
    'knowledge segments',
    'statistical data',
    'performance metrics',
    'analytical insights',
    'fact collections',
    'numerical figures',
    'data points',
    'dataset compilations',
    'input variables',
    'raw materials',
    'recorded entries',
    'observational studies',
    'feature sets',
    'sample groups',
    'training datasets',
    'signal patterns',
    'customer datasets',
    'transaction records',
    'sales datasets',
    'revenue reports',
    'purchase histories',
    'sales trend analyses',
    'market data analyses',
    'customer insights',
    'sales analytics reports',
    'sales performance metrics',
    'product data analyses',
    'user behavior studies',
    'inventory levels',
    'financial reports',
    'demographic profiles',
  ];

  const decisionsWords = [
    'strategic choices',
    'judgment calls',
    'conclusive findings',
    'selected options',
    'resolution paths',
    'determination points',
    'resulting outcomes',
    'issued verdicts',
    'proposed solutions',
    'predictive models',
    'recommendation lists',
    'strategic plans',
    'actionable steps',
    'algorithmic adjustments',
    'modeling approaches',
    'optimization techniques',
    'classification schemes',
    'forecasting methods',
    'automation processes',
    'sales strategy adaptations',
    'pricing models',
    'marketing strategies',
    'sales forecasts',
    'inventory management systems',
    'product recommendation engines',
    'sales optimization strategies',
    'customer targeting methods',
    'sales conversion tactics',
    'sales growth strategies',
    'business strategy decisions',
    'data-driven insights',
    'decision-making frameworks',
    'strategic planning sessions',
    'outcome analyses',
  ];

  const randomDataWord = dataWords[Math.floor(Math.random() * dataWords.length)];
  const randomDecisionsWord = decisionsWords[Math.floor(Math.random() * decisionsWords.length)];

  return {
    data: randomDataWord,
    decisions: randomDecisionsWord,
  };
}

export default function DashHeader({ setIsSidebarOpen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profileImageUrl, user, token } = useSelector((state) => state.auth);
  const { notifications } = useSelector((state) => state.settings);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate('/');
  };

  const [words, setWords] = useState(generateWords());
  const [bouncy, setBouncy] = useState(false);
  const [darkMode, setDarkMode] = useState(() => localStorage.getItem('theme') === 'dark');
  const [showAppsDropdown, setShowAppsDropdown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReferModalOpen, setIsReferModalOpen] = useState(false); // State for Refer a Friend modal

  useEffect(() => {
    const interval = setInterval(() => {
      setBouncy(true);
      setTimeout(() => {
        setWords(generateWords());
        setTimeout(() => {
          setBouncy(false);
        }, 500);
      }, 2000);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }

    const themeEvent = new CustomEvent('themeChange', {
      detail: { theme: darkMode ? 'dark' : 'light' },
    });
    window.dispatchEvent(themeEvent);
  }, [darkMode]);

  return (
    <nav className='fixed z-30 w-full border-b dark:border-gray-700  bg-white dark:bg-gray-800'>
      <div className='px-3 py-3 lg:px-5 lg:pl-3'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center justify-start'>
            <button
              id='toggleSidebarMobile'
              aria-expanded='true'
              aria-controls='sidebar'
              className='p-2 text-gray-600 rounded cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              onClick={() => setIsSidebarOpen((prev) => !prev)}
            >
              <svg
                id='toggleSidebarMobileHamburger'
                className='w-6 h-6'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                  clipRule='evenodd'
                />
              </svg>
              <svg
                id='toggleSidebarMobileClose'
                className='hidden w-6 h-6'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 111.414 1.414L11.414 10l4.293 4.293a1 1 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 01-1.414-1.414L8.586 10 4.293 5.707a1 1 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
            <Link to='/' className='flex ml-2 md:mr-24'>
              <img
                src={darkMode ? arbiEngineLogoDark : arbiEngineLogoLight}
                className='h-8 mr-3'
                alt='Arbi Engine Logo'
              />
              <span className='hidden lg:inline self-center text-xs font-semibold sm:text-sm whitespace-nowrap dark:text-white'>
                <p
                  className={`${bouncy ? 'animate-pulse' : ''} text-gray-800 dark:text-white font-bold pr-4`}
                >
                  Where{' '}
                  <span className='text-indigo-600 dark:text-indigo-400'>
                    {words.data}
                  </span>{' '}
                  drive{' '}
                  <span
                    className={`text-indigo-600 dark:text-indigo-400 ${bouncy ? 'animate-bounce' : ''}`}
                  >
                    {words.decisions}
                  </span>
                  .
                </p>
              </span>
            </Link>
          </div>
          <div className='flex items-center'>
            {token && user && (
              <button
                type='button'
                className='p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex items-center'
                onClick={() => setIsReferModalOpen(true)}
              >
                <ShareIcon className='h-6 w-6' aria-hidden='true' />
                <span className='ml-2 text-sm'>Refer a Friend</span> {/* Label for clarity */}
              </button>
            )}
            <button
              id='toggleSidebarMobileSearch'
              type='button'
              className='p-2 text-gray-500 rounded-lg lg:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
            >
              <span className='sr-only'>Search</span>
              <svg
                className='w-6 h-6'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
            <Menu as='div' className='relative'>
              <div>
                <Menu.Button className='p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'>
                  <span className='sr-only'>View notifications</span>
                  <BellIcon className='h-6 w-6' aria-hidden='true' />
                  {notifications.length > 0 && (
                    <span className='absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white dark:ring-gray-900 bg-red-400'></span>
                  )}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
                  {notifications.length === 0 ? (
                    <div className='px-4 py-3 text-sm text-gray-700 dark:text-gray-300'>
                      No notifications
                    </div>
                  ) : (
                    notifications.map((notification, index) => (
                      <Menu.Item key={index}>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? 'bg-gray-100 dark:bg-gray-600' : '',
                              'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300'
                            )}
                          >
                            {notification.message}
                          </div>
                        )}
                      </Menu.Item>
                    ))
                  )}
                </Menu.Items>
              </Transition>
            </Menu>
            <Link
              to='/settings/help'
              className='p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
            >
              <span className='sr-only'>Help and Support</span>
              <QuestionMarkCircleIcon className='h-6 w-6' aria-hidden='true' />
            </Link>
            <button
              type='button'
              className='p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              onClick={() => setIsModalOpen(true)}
            >
              <span className='sr-only'>View walkthrough</span>
              <InformationCircleIcon className='h-6 w-6' aria-hidden='true' />
            </button>
            <div className='hidden lg:flex items-center relative'>
              <button
                type='button'
                className='p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                onClick={() => setShowAppsDropdown(!showAppsDropdown)}
              >
                <span className='sr-only'>View apps</span>
                <ViewGridIcon className='w-6 h-6' />
              </button>
              {showAppsDropdown && (
                <div className='absolute right-0 mt-2 w-96 rounded-md shadow-lg py-3 bg-gradient-to-r bg-white border dark:from-gray-800 dark:to-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none z-50 top-12 '>
                  <div className='block px-4 py-2 text-base font-medium text-center text-gray-700 dark:text-gray-400'>
                    Arbi Quick Links
                  </div>
                  <div className='grid grid-cols-3 gap-4 p-4 text-center'>
                    <Link
                      to='/arbie/purchases'
                      className='block p-6 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600'
                      onClick={() => setShowAppsDropdown(false)}
                    >
                      <ShoppingCartIcon className='mx-auto mb-2 text-gray-500 w-8 h-8 dark:text-gray-400' />
                      <div className='mt-1 text-sm font-medium text-gray-900 dark:text-white'>
                        Buys
                      </div>
                    </Link>
                    <Link
                      to='/arbie/results-feed'
                      className='block p-6 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600'
                      onClick={() => setShowAppsDropdown(false)}
                    >
                      <ChartSquareBarIcon className='mx-auto mb-2 text-gray-500 w-8 h-8 dark:text-gray-400' />
                      <div className='mt-1 text-sm font-medium text-gray-900 dark:text-white'>
                        Results
                      </div>
                    </Link>
                    <Link
                      to='/arbie'
                      className='block p-6 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600'
                      onClick={() => setShowAppsDropdown(false)}
                    >
                      <ViewGridIcon className='mx-auto mb-2 text-gray-500 w-8 h-8 dark:text-gray-400' />
                      <div className='mt-1 text-sm font-medium text-gray-900 dark:text-white'>
                        Dashboard
                      </div>
                    </Link>
                    <Link
                      to='/arbie/ecom-products'
                      className='block p-6 text-center rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600'
                      onClick={() => setShowAppsDropdown(false)}
                    >
                      <CubeIcon className='mx-auto mb-2 text-gray-500 w-8 h-8 dark:text-gray-400' />
                      <div className='mt-1 text-sm font-medium text-gray-900 dark:text-white'>
                        Inventory
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <button
              id='theme-toggle'
              data-tooltip-target='tooltip-toggle'
              type='button'
              className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5'
              onClick={() => {
                setDarkMode(!darkMode);
                setShowAppsDropdown(false);
              }}
            >
              {darkMode ? (
                <SunIcon className='w-5 h-5' />
              ) : (
                <MoonIcon className='w-5 h-5' />
              )}
            </button>
            <div
              id='tooltip-toggle'
              role='tooltip'
              className='absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip'
            >
              Toggle dark mode
              <div className='tooltip-arrow' data-popper-arrow></div>
            </div>
            <Menu as='div' className='ml-3 relative'>
              <div>
                <Menu.Button className='bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600'>
                  <span className='sr-only'>Open user menu</span>
                  <img
                    className='h-8 w-8 rounded-full border-dark-purple border-2 object-scale-down'
                    src={
                      profileImageUrl ||
                      'https://cdn-icons-png.flaticon.com/512/9131/9131529.png'
                    }
                    alt=''
                    onClick={() => setShowAppsDropdown(false)}
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/arbie'
                        className={classNames(
                          active ? 'bg-gray-100 dark:bg-gray-600' : '',
                          'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300'
                        )}
                        onClick={() => setShowAppsDropdown(false)}
                      >
                        Dashboard
                      </Link>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/settings'
                        className={classNames(
                          active ? 'bg-gray-100 dark:bg-gray-600' : '',
                          'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300'
                        )}
                        onClick={() => setShowAppsDropdown(false)}
                      >
                        Settings
                      </Link>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/'
                        className={classNames(
                          active ? 'bg-gray-100 dark:bg-gray-600' : '',
                          'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300'
                        )}
                        onClick={() => setShowAppsDropdown(false)}
                      >
                        Home
                      </Link>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          setShowAppsDropdown(false);
                          onLogout();
                        }}
                        className={classNames(
                          active ? 'bg-gray-100 dark:bg-gray-600' : '',
                          'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 w-full text-left'
                        )}
                      >
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <UserWalkthroughModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <ReferFriendModal
        isOpen={isReferModalOpen}
        onClose={() => setIsReferModalOpen(false)}
      />
    </nav>
  );
}
