import React, { useState, useEffect } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { BellIcon, SunIcon, MoonIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reset, logout } from '../features/auth/authSlice';
import arbiEngineLogoDark from '../images/arbienginelogo.png';
import arbiEngineLogoLight from '../images/arbieBlackNew.png';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header({ hideGetStarted = false }) {
  const [darkMode, setDarkMode] = useState(
    () => localStorage.getItem('theme') === 'dark'
  );
  const [navigation, setNavigation] = useState([
    { name: 'Login', href: '/login', current: false },
    { name: 'Register', href: '/register', current: false },
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, profileImageUrl } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate('/');
  };

  useEffect(() => {
    setNavigation(
      user
        ? [
            { name: 'Home', href: '/', current: false },
            { name: 'Dashboard', href: '/arbie', current: false },
            { name: 'Results Feed', href: '/arbie/results-feed', current: false },
            { name: 'Search with Arbi', href: '/arbie/arbie-scout', current: false },
            { name: 'Saved Item Configurator', href: '/saved-item-configurator', current: false },
          ]
        : [
            { name: 'Login', href: '/login', current: false },
            { name: 'Register', href: '/register', current: false },
          ]
    );
  }, [user]);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');  // Fixed typo here
    }

    const themeEvent = new CustomEvent('themeChange', {
      detail: { theme: darkMode ? 'dark' : 'light' },
    });
    window.dispatchEvent(themeEvent);
  }, [darkMode]);

  return (
    <Disclosure as="header" className="relative z-20 ">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex ml-2 md:mr-24" aria-label="Arbi Engine Home">
            <img
              src={darkMode ? arbiEngineLogoDark : arbiEngineLogoLight}
              className="h-8 mr-3"
              alt="Arbi Engine Logo - Discover Ecommerce Arbitrage Opportunities"
            />
            <span className="hidden lg:inline self-center text-xs font-semibold sm:text-sm whitespace-nowrap dark:text-white">
              <p className={`text-gray-800 dark:text-white font-bold pr-4`}>
                Where <span className="text-indigo-600 dark:text-indigo-400">data</span> drive{' '}
                <span className={`text-indigo-600 dark:text-indigo-400`}>decisions</span>.
              </p>
            </span>
          </Link>
          <div className="flex items-center">
            <button
              id="theme-toggle"
              type="button"
              className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
              onClick={() => setDarkMode(!darkMode)}
              aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              {darkMode ? <SunIcon className="w-5 h-5" /> : <MoonIcon className="w-5 h-5" />}
            </button>
            {user ? (
              <>
                <button
                  type="button"
                  className="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  aria-label="View notifications"
                >
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <Link
                  to="/arbie"
                  className="ml-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  title="Go to your Dashboard"
                >
                  Dashboard
                </Link>
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button
                      className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                      aria-label="Open user menu"
                    >
                      <img
                        className="h-8 w-8 rounded-full border-dark-purple border-2 object-scale-down"
                        src={profileImageUrl || 'https://cdn-icons-png.flaticon.com/512/9131/9131529.png'}
                        alt="User profile"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/arbie"
                            className={classNames(active ? 'bg-gray-100 dark:bg-gray-600' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
                          >
                            Dashboard
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/settings"
                            className={classNames(active ? 'bg-gray-100 dark:bg-gray-600' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
                          >
                            Settings
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={onLogout}
                            className={classNames(active ? 'bg-gray-100 dark:bg-gray-600' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 w-full text-left')}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </>
            ) : (
              !hideGetStarted && (
                <Link
                  to='/register-closed'
                  className='ml-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  title="Join the Arbi Engine Waitlist"
                >
                  Join the Waitlist
                </Link>
              )
            )}
          </div>
        </div>
      </div>
      <Disclosure.Panel className="sm:hidden">
        <div className="px-2 pt-2 pb-3 space-y-1">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className={classNames(item.current ? 'bg-gray-900 text-white' : 'text-gray-700 dark:text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium')}
              aria-current={item.current ? 'page' : undefined}
            >
              {item.name}
            </Link>
          ))}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
}
