import React from 'react';
import { SearchIcon, PlusIcon, ViewGridIcon, ViewListIcon } from '@heroicons/react/solid';

const ProductsActions = ({ onAddProduct, toggleView, cardView }) => {
  return (
    <div className="flex flex-col space-y-4 bg-gray-50 dark:bg-gray-900 pt-4">
      {/* Main Header Section */}
      <div className="flex flex-col md:flex-row items-center justify-between p-6 bg-white rounded-xl shadow-lg  dark:bg-gray-800 border dark:border-gray-700">
        {/* Title Section */}
        <div className="flex items-center space-x-4 mb-4 md:mb-0">
          <ViewGridIcon className="w-10 h-10 text-indigo-600 dark:text-indigo-400" />
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Inventory Management</h1>
        </div>
        {/* Action Buttons Section */}
        <div className="flex space-x-4">
          {/* View Toggle Button */}
          <button
            onClick={toggleView}
            className="bg-gradient-to-r from-indigo-500 to-indigo-800 text-white font-medium py-2 px-4 rounded-lg hover:from-indigo-600 hover:to-indigo-900 flex items-center space-x-2 transition duration-300 ease-in-out"
          >
            {cardView ? (
              <>
                <ViewListIcon className="h-5 w-5" />
                <span>Table View</span>
              </>
            ) : (
              <>
                <ViewGridIcon className="h-5 w-5" />
                <span>Card View</span>
              </>
            )}
          </button>

          {/* Add Product Button */}
          <button
            onClick={onAddProduct}
            className="bg-indigo-600 text-white font-medium py-2 px-4 rounded-lg hover:bg-indigo-700 flex items-center space-x-2 transition duration-300 ease-in-out"
          >
            <PlusIcon className="h-5 w-5" />
            <span>Add Product</span>
          </button>
        </div>
      </div>

      {/* Search Bar Section */}
      <div className="p-4 bg-white  dark:bg-gray-800 rounded-xl shadow-lg border dark:border-gray-700">
        <div className="relative flex items-center">
          <SearchIcon className="absolute w-5 h-5 text-gray-700 dark:text-gray-300 ml-3" />
          <input
            type="text"
            id="products-search"
            className="block w-full pl-10 pr-4 py-2 text-sm text-gray-900 border border-gray-300 dark:border-gray-700 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800  dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
            placeholder="Search for products"
          />
        </div>
      </div>
    </div>
  );
};

export default ProductsActions;
