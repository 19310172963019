import { configureStore } from '@reduxjs/toolkit'

import authReducer from '../features/auth/authSlice'
import connectionReducer from '../features/connections/connectionSlice'
import inventoryReducer from '../features/inventory/inventorySlice.js'
import autoFeedReducer from '../features/arbitrage/autoFeedSlice.js'
import metricsReducer from '../features/metrics/metricsSlice'
import productResearchReducer from '../features/productResearch/productResearchSlice'
import subscriptionReducer from '../features/subscriptions/subscriptionSlice'
import settingsReducer from '../features/userSettings/settingsSlice'
import purchasesReducer from '../features/purchases/purchasesSlice'
import ordersReducer from '../features/orders/ordersSlice'
import organizationReducer from '../features/organization/organizationSlice';
import marketingReducer from '../features/marketing/marketingSlice' 

export const store = configureStore({
  reducer: {
    auth: authReducer,
    connection: connectionReducer,
    inventory: inventoryReducer,
    autoFeed: autoFeedReducer,
    metrics: metricsReducer,
    productResearch: productResearchReducer,
    subscription: subscriptionReducer,
    settings: settingsReducer,
    purchases: purchasesReducer,
    orders: ordersReducer,
    marketing: marketingReducer,
    organization: organizationReducer,
  },
})
