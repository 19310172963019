import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'

import DashHeader from '../../components/Dashboard/Header/DashHeader'
import DashSidebar from '../../components/Dashboard/DashSidebar'

const DefaultLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <div className='flex h-screen overflow-hidden bg-gray-50 dark:bg-gray-900'>
      <DashHeader setIsSidebarOpen={setIsSidebarOpen} />
      <DashSidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div className='flex flex-1 overflow-hidden pt-16'>
        <main className='flex-1 overflow-y-auto'>
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default DefaultLayout
