import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMetricsData } from './metricsService';

const initialState = {
  inventoryMetrics: {},
  arbitrageOpportunitiesMetrics: {},
  inventoryGrowthMetrics: {},
  analyzedListingsMetrics: {},
  searchTermsMetrics: {},
  inventoryHistory: {}, // State for inventory history
  inventoryAgeMetrics: {}, // New state for inventory age metrics
  statusCounts: { // Initialize status counts
    total: 0,
    passed: 0,
    watching: 0,
    pending: 0,
  },
  newListingsCounts: {},
  topSearchTermsMetrics: [],
  topOrderSearchTermsMetrics: [],
  weeklyInventoryMetrics: [],
  purchases: [],
  loading: false,
  error: null,
};

// Async thunks
export const getSearchTermsMetrics = createAsyncThunk(
  'metrics/getSearchTermsMetrics',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'search-terms', userId); // Adjust the endpoint as necessary
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunks for existing metrics
export const getInventoryMetrics = createAsyncThunk(
  'metrics/getInventoryMetrics',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'inventory');
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getArbitrageOpportunitiesMetrics = createAsyncThunk(
  'metrics/getArbitrageOpportunitiesMetrics',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'arbitrage-opportunities');
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getInventoryGrowthMetrics = createAsyncThunk(
  'metrics/getInventoryGrowthMetrics',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'inventory-growth');
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAnalyzedListingsMetrics = createAsyncThunk(
  'metrics/getAnalyzedListingsMetrics',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'analyzed-listings');
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// New async thunk for fetching the 12-month inventory history
export const getInventoryHistory = createAsyncThunk(
  'metrics/getInventoryHistory',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'inventory-history');
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// New async thunk for fetching inventory age metrics
export const getInventoryAgeMetrics = createAsyncThunk(
  'metrics/getInventoryAgeMetrics',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'inventory-age');
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAnalyzedListingsMetricsV2 = createAsyncThunk(
  'metrics/getAnalyzedListingsMetricsV2',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'analyzed-listings-v2');
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getWeeklyInventoryMetrics = createAsyncThunk(
  'metrics/getWeeklyInventoryMetrics',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'weekly-inventory');
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to fetch purchases data
export const getUserPurchases = createAsyncThunk(
  'metrics/getUserPurchases',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'user-purchases'); // Assuming we have an endpoint for purchases
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch top search terms metrics
export const getTopSearchTermsMetrics = createAsyncThunk(
  'metrics/getTopSearchTermsMetrics',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'top-search-terms');
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getTopOrderSearchTermsMetrics = createAsyncThunk(
  'metrics/getTopOrderSearchTermsMetrics',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchMetricsData(token, 'top-order-search-terms');
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Inventory Metrics
    builder
      .addCase(getInventoryMetrics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventoryMetrics.fulfilled, (state, action) => {
        state.inventoryMetrics = action.payload;
        state.loading = false;
      })
      .addCase(getInventoryMetrics.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Arbitrage Opportunities Metrics
      .addCase(getArbitrageOpportunitiesMetrics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getArbitrageOpportunitiesMetrics.fulfilled, (state, action) => {
        state.arbitrageOpportunitiesMetrics = action.payload;
        state.loading = false;
      })
      .addCase(getArbitrageOpportunitiesMetrics.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Inventory Growth Metrics
      .addCase(getInventoryGrowthMetrics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventoryGrowthMetrics.fulfilled, (state, action) => {
        state.inventoryGrowthMetrics = action.payload;
        state.loading = false;
      })
      .addCase(getInventoryGrowthMetrics.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Analyzed Listings Metrics
      .addCase(getAnalyzedListingsMetrics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnalyzedListingsMetrics.fulfilled, (state, action) => {
        state.analyzedListingsMetrics = action.payload;
        state.loading = false;
      })
      .addCase(getAnalyzedListingsMetrics.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Search Terms Metrics
      .addCase(getSearchTermsMetrics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSearchTermsMetrics.fulfilled, (state, action) => {
        state.searchTermsMetrics = action.payload;
        state.loading = false;
      })
      .addCase(getSearchTermsMetrics.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Inventory History Metrics
      .addCase(getInventoryHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventoryHistory.fulfilled, (state, action) => {
        state.inventoryHistory = action.payload;
        state.loading = false;
      })
      .addCase(getInventoryHistory.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Inventory Age Metrics
      .addCase(getInventoryAgeMetrics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventoryAgeMetrics.fulfilled, (state, action) => {
        state.inventoryAgeMetrics = action.payload;
        state.loading = false;
      })
      .addCase(getInventoryAgeMetrics.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getAnalyzedListingsMetricsV2.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnalyzedListingsMetricsV2.fulfilled, (state, action) => {
        const { statusCounts, newListingsCounts } = action.payload;
        const { total, passed, watching, pending } = statusCounts;
        state.statusCounts = { total, passed, watching, pending };
        state.newListingsCounts = newListingsCounts; // Update newListingsCounts state
        state.loading = false;
      })
      .addCase(getAnalyzedListingsMetricsV2.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getWeeklyInventoryMetrics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWeeklyInventoryMetrics.fulfilled, (state, action) => {
        state.weeklyInventoryMetrics = action.payload;
        state.loading = false;
      })
      .addCase(getWeeklyInventoryMetrics.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getUserPurchases.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserPurchases.fulfilled, (state, action) => {
        state.purchases = action.payload;
        state.loading = false;
      })
      .addCase(getUserPurchases.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getTopSearchTermsMetrics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTopSearchTermsMetrics.fulfilled, (state, action) => {
        state.topSearchTermsMetrics = action.payload;
        state.loading = false;
      })
      .addCase(getTopSearchTermsMetrics.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getTopOrderSearchTermsMetrics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTopOrderSearchTermsMetrics.fulfilled, (state, action) => {
        state.topOrderSearchTermsMetrics = action.payload;
        state.loading = false;
      })
      .addCase(getTopOrderSearchTermsMetrics.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default metricsSlice.reducer;
