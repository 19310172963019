import React from 'react';
import {
  PencilIcon,
  TrashIcon,
  CameraIcon,
  ExternalLinkIcon,
} from '@heroicons/react/outline';
import { Tooltip } from 'react-tooltip';

const ProductsDataCard = ({ items, onEdit, onDelete, onEditPhotos, onCardClick }) => {
  const defaultImageUrl =
    'https://cdn-icons-png.flaticon.com/512/3342/3342243.png';

  const StatusIndicator = ({ status, tooltip }) => (
    <div className="relative flex items-center">
      <div
        className={`h-4 w-4 rounded-full ${status === 'active' || status === 'completed' ? 'bg-green-500' : 'bg-gray-500'
          }`}
        data-tip={tooltip}
      ></div>
      <Tooltip place="top" type="dark" effect="solid" />
    </div>
  );

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 ">
      {items.length > 0 ? (
        items.map((item) => (
          <div
            key={item._id}
            className="bg-white border dark:border-gray-700 dark:bg-gray-800 shadow-lg rounded-2xl p-6 flex flex-col justify-between space-y-6 transform hover:scale-95 transition-transform duration-300 cursor-pointer"
            onClick={() => onCardClick(item._id)} // Handle card click for navigation
          >
            {/* Image and Title */}
            <div className="flex items-center space-x-4">
              <img
                src={
                  item.images && item.images.length > 0
                    ? item.images[0].awsUrl
                    : defaultImageUrl
                }
                alt={item.name}
                className="w-28 h-28 rounded-lg object-cover"
              />
              <div className="flex-1 min-w-0">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white truncate">
                  {item.sku}
                </h2>
                <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                  {item.brand} {item.model} {item.item_description}
                </p>
                <p className="text-lg text-indigo-600 dark:text-indigo-400 font-bold">
                  ${item.price.toFixed(2)}
                </p>
                <p className="text-xs text-gray-600 dark:text-gray-400">
                  Quantity: {item.quantity}
                </p>
                <p className="text-xs text-gray-600 dark:text-gray-400">
                  Condition: {item.condition || 'N/A'}
                </p>
              </div>
            </div>

            {/* Status and Actions */}
            <div className="flex justify-between items-center">
              {/* Status Indicators */}
              <div className="flex space-x-3">
                <div className="flex items-center space-x-1">
                  <StatusIndicator
                    status={item.ebayListingStatus}
                    tooltip={`eBay Listing Status: ${item.ebayListingStatus}`}
                  />
                  <span className="text-xs text-gray-600 dark:text-gray-300">
                    eBay
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <StatusIndicator
                    status={item.photographyStatus}
                    tooltip={`Photography Status: ${item.photographyStatus}`}
                  />
                  <span className="text-xs text-gray-600 dark:text-gray-300">
                    Photos
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <StatusIndicator
                    status={item.listingStatus}
                    tooltip={`Listing Status: ${item.listingStatus}`}
                  />
                  <span className="text-xs text-gray-600 dark:text-gray-300">
                    Listing
                  </span>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex space-x-3" onClick={(e) => e.stopPropagation()}> {/* Prevent click from propagating to card */}
                <button
                  onClick={() => onEdit(item)}
                  className="text-indigo-600 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full p-2"
                >
                  <PencilIcon className="h-6 w-6" />
                </button>
                {item.ebayDetails?.ebayListingId && (
                  <a
                    href={`https://www.ebay.com/itm/${item.ebayDetails.ebayListingId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-600 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full p-2"
                  >
                    <ExternalLinkIcon className="h-6 w-6" />
                  </a>
                )}
                <button
                  onClick={() => onDelete(item)}
                  className="text-red-600 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full p-2"
                >
                  <TrashIcon className="h-6 w-6" />
                </button>
                {item.photographyStatus === 'completed' && (
                  <button
                    onClick={() => onEditPhotos(item)}
                    className="text-indigo-600 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full p-2"
                  >
                    <CameraIcon className="h-6 w-6" />
                  </button>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center col-span-full">
          <p className="text-gray-600 dark:text-gray-400">
            No products available
          </p>
        </div>
      )}
    </div>
  );
};

export default ProductsDataCard;
