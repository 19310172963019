import React, { useState, useCallback, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { toast } from 'react-toastify'

import { uploadEditedImagesForItem } from '../../../features/inventory/inventorySlice'
import Spinner from '../../Spinner'

const ImageItem = ({ image, index, moveImage, onDelete }) => {
  const ref = React.useRef(null)
  const [, drop] = useDrop({
    accept: 'image',
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientX = clientOffset.x - hoverBoundingRect.left

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }
      moveImage(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: () => ({ id: image.id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drag(drop(ref))

  return (
    <div
      ref={ref}
      className='inline-flex flex-col items-center justify-center p-2 border rounded shadow-lg bg-white min-w-[250px]'
      style={{ opacity: isDragging ? 0.5 : 1, cursor: 'grab', width: 200 }}
    >
      <span className='mb-2 text-sm font-semibold'>Image #{index + 1}</span>
      <img
        src={image.preview}
        alt={`Preview ${index + 1}`}
        className='w-full h-48 object-scale-down  min-w-[200px] p-1'
      />
      <button
        onClick={() => onDelete(image.id)}
        className='mt-2 bg-red-500 text-white p-1 rounded'
      >
        Delete
      </button>
    </div>
  )
}

const EditPhotographyModal = ({ isOpen, onClose, item }) => {
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [images, setImages] = useState(() =>
    item.images.map((img, index) => ({
      ...img,
      preview: img.awsUrl || URL.createObjectURL(img),
      id: img.id || Math.random(),
      order: index,
    }))
  )
  const [deletedImages, setDeletedImages] = useState([])

  const handleImageChange = (e) => {
    // Check if files were selected
    if (e.target.files) {
      const maxImagesAllowed = 24
      const selectedFiles = Array.from(e.target.files)
      const spaceAvailable = maxImagesAllowed - images.length

      // Limit the number of files based on how many images are already selected
      const filesToAdd = selectedFiles.slice(0, spaceAvailable)

      if (filesToAdd.length < selectedFiles.length) {
        toast.warn(`You can only add up to ${spaceAvailable} more image(s).`)
      }

      // Create new images for the UI from the files to add
      const newImages = filesToAdd.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        id: Math.random(), // Consider using a more robust ID generation strategy
        order: images.length + filesToAdd.indexOf(file),
      }))

      // Update state with new images
      setImages((prevImages) => [...prevImages, ...newImages])
    }
  }

  const moveImage = useCallback(
    (dragIndex, hoverIndex) => {
      const dragImage = images[dragIndex]
      const newImages = [...images]
      newImages.splice(dragIndex, 1)
      newImages.splice(hoverIndex, 0, dragImage)
      setImages(newImages.map((img, index) => ({ ...img, order: index })))
    },
    [images]
  )

  const handleDeleteImage = (id) => {
    const updatedImages = images.filter((image) => image.id !== id)
    const imageToDelete = images.find((image) => image.id === id)

    // For existing images, track the deletion for backend processing
    if (imageToDelete && imageToDelete.awsUrl) {
      setDeletedImages((prev) => [...prev, imageToDelete])
    }

    // Update state to reflect the deletion
    setImages(updatedImages)
  }

  const handleSubmit = async () => {
    if (images.length === 0 || images.length > 24) {
      toast.error(
        'Please adjust the number of images. You must have at least 1 and no more than 24.'
      )
      return
    }

    setIsSubmitting(true)

    const formData = new FormData()
    // Initialize the imageOrderMapping array to collect metadata for all images
    const imageOrderMapping = []

    // Add metadata for new and existing images
    images.forEach((image, index) => {
      // Assuming images that have a file property are new or updated
      if (image.file) {
        formData.append('images', image.file)
        imageOrderMapping.push({
          filename: image.file.name, // Use file.name for new images
          order: index,
          status: image.id ? 'updated' : 'new', // Mark as 'updated' if it has an id, else 'new'
        })
      } else if (image.id) {
        // Existing images that haven't been updated but may have been reordered
        imageOrderMapping.push({
          filename: image.filename, // Use stored filename for existing images
          awsUrl: image.awsUrl, // Include AWS URL for backend to identify the image
          order: index,
          status: 'existing', // Mark as 'existing'
        })
      }
    })

    // Include metadata for deleted images
    // Assuming `deletedImages` is an array in your component's state
    deletedImages.forEach((deletedImage) => {
      imageOrderMapping.push({
        filename: deletedImage.filename, // The filename of the deleted image
        awsUrl: deletedImage.awsUrl, // AWS URL of the deleted image for backend identification
        status: 'deleted', // Mark as 'deleted'
      })
    })

    // Append the image metadata as a JSON string
    formData.append('imagesMetadata', JSON.stringify(imageOrderMapping))

    try {
      // Dispatch the action to upload images along with their metadata
      await dispatch(
        uploadEditedImagesForItem({ inventoryItemId: item._id, formData })
      ).unwrap()
      toast.success('Images updated successfully')
      onClose() // Close the modal or reset state as needed
      setDeletedImages([]) // Clear the deletedImages state after successful upload
    } catch (error) {
      toast.error(`Error updating images: ${error.message}`)
    } finally {
      setIsSubmitting(false)
    }
  }

  if (!isOpen) return null

  return (
    <>
      {isSubmitting && <Spinner />}
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={onClose}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />
            </Transition.Child>
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  Edit Product Images
                </Dialog.Title>
                <div className='mt-4'>
                  <input
                    type='file'
                    multiple
                    accept='image/*'
                    onChange={handleImageChange}
                    className='mb-2'
                    disabled={images.length >= 24}
                  />
                  <DndProvider backend={HTML5Backend}>
                    <div
                      className='flex overflow-x-auto py-2 gap-4'
                      style={{ minHeight: '200px' }}
                    >
                      {images
                        .sort((a, b) => a.order - b.order)
                        .map((image, index) => (
                          <ImageItem
                            key={image.id}
                            image={image}
                            index={index}
                            moveImage={moveImage}
                            onDelete={handleDeleteImage}
                          />
                        ))}
                    </div>
                  </DndProvider>
                </div>
                <div className='mt-4 flex justify-end'>
                  <button
                    type='button'
                    className={`inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ${
                      isSubmitting || images.length === 0 || images.length > 24
                        ? 'bg-red-200 text-red-900 cursor-not-allowed hover:bg-red-200'
                        : 'text-blue-900 bg-blue-100 hover:bg-blue-200 focus-visible:ring-blue-500'
                    }`}
                    onClick={handleSubmit}
                    disabled={
                      isSubmitting || images.length === 0 || images.length > 24
                    }
                  >
                    {isSubmitting ? 'Processing...' : 'Save Changes'}
                  </button>

                  <button
                    type='button'
                    className='ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500'
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default EditPhotographyModal
